import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped.js';
import parseQuerystring from '../../../helpers/parseQuerystring.js';
import TextFieldInput from '../../shared/Inputs/TextField.js';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';

import combineStyles from '../../../helpers/combineStyles.js';
import SpacingStyles from '../../../styles/helpers/spacing.js';

import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants.js';
import { statusOptions } from './dropDownOptions.js';

class SupplierProposalsFilters extends PureComponent {
  state = {
    nit: '',
    companyName: '',
    status: 'todos',
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    this.setState({
      ...this.state,
      ...query,
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true,
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, (filter) => !!filter),
      'companyName',
      'status',
      'nit'
    );
    return filterParams;
  };

  render() {
    const { classes } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Grid container spacing={16}>
            <Grid item xs={12} sm={4}>
              <TextFieldInput
                id="companyName"
                name="companyName"
                value={this.state.companyName}
                onChange={this._handleChange}
                label="Nombre"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextFieldInput
                id="nit"
                name="nit"
                value={this.state.nit}
                onChange={this._handleChange}
                label="NIT"
                fullWidth
                margin="normal"
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <OutlinedSelectChippedInput
                label="Estado"
                classes={{
                  root: classes.selectRoot,
                  filterColor: classes.filterColor
                }}
                name="status"
                options={statusOptions}
                onChange={this._handleSelectChange}
                inputLabel="status"
                value={this.state.status}
                withChip={false}
              />
            </Grid>
          </Grid>
        }
      />
    );
  }
}

const styles = (theme) => ({});

SupplierProposalsFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(SupplierProposalsFilters);
