import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/styles';
import _isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';
import ButtonStyles from '../../../styles/button';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import SupplierProposalsFilters from './SupplierProposalsFilters';
import classnames from 'classnames';

import ListsStyle from '../__styles__/Lists.style';
import combineStyles from '../../../helpers/combineStyles';
import parseQuerystring from '../../../helpers/parseQuerystring';
import LayoutStyles from '../../../styles/layout';
import TypographyStyles from '../../../styles/typography';

class SupplierProposalsList extends Component {
  state = {
    rowsPerPage: sessionStorage.getItem('SupplierProposalListRowsPerPage')
      ? parseInt(sessionStorage.getItem('SupplierProposalListRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    page: sessionStorage.getItem('SupplierProposalListPage')
      ? parseInt(sessionStorage.getItem('SupplierProposalListPage'), 0)
      : 0
  }

  componentDidMount() {
    this._fetchItems();
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchSupplierProposals({
      ...query,
      page: query.page || 1,
      rowsPerPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('SupplierProposalListRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage, page: 1 }, this._fetchItems);
  };

  render() {
    const {
      proposals,
      classes,
      fetchingProposals,
      totalCount,
      goToProposal,
      goToCreatePage, 
      currentPage
    } = this.props;
    return (
      <>
        <Header
          classes={classes}
          goToCreatePage={goToCreatePage}
        />
        <div>
          <div className={classes.filterOptionsWrapper}>
            <SupplierProposalsFilters onChangeFilter={this._fetchItems}/>
          </div>
          <div>
            <Table
              name={proposals.length === 1 ? 'Propuesta' : 'Propuestas'}
              columns={columns}
              data={mapData(proposals)}
              onChangePage={this._handleChangePage}
              onChangePageRowsPerPage={this._handleChangeRowsPerPage}
              page={Number(currentPage - 1)}
              rowsPerPage={this.state.rowsPerPage}
              totalCount={totalCount}
              loading={fetchingProposals}
              onClickOnRow={proposal => goToProposal({ id: proposal.id })}
            />
          </div>
        </div>
      </>
    );
  };
}

const Header = props => {
  const { classes, goToCreatePage } = props;
  return (
    <div className={classes.titleRootWrapper}>
      <h1 className={classnames(classes.headline4, classes.title)}>
        Propuestas
      </h1>
      <Button
        className={classes.button}
        classes={{
          label: classes.buttonLabel
        }}
        variant="contained"
        color="secondary"
        onClick={() => goToCreatePage()}
      >
        Crear Propuesta
      </Button>
    </div>
  );
};

export default withStyles(
  combineStyles(
    styles,
    ButtonStyles,
    ListsStyle,
    TypographyStyles,
    LayoutStyles
  )
)(SupplierProposalsList);