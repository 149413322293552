/* eslint-disable complexity */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Formik, Form, Field } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import SegmentHeader from '../../shared/SegmentHeader';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { debtorValidation, cosignerValidation } from './DigitalSaleUnmaterializedPromissoryValidation';
import {
  initialValuesSanitizer,
  submitSanitizer
} from '../../../helpers/sanitizers/portalPromissorySanitizer';
import StepperButtons from '../../shared/StepperButtons';
import OurDatePicker from '../../shared/CustomDatePicker';
import AutoSuggestInput from '../../shared/AutoSuggestInput';
import ActivityIndicator from '../../shared/ActivityIndicator';
import _get from 'lodash/get';

import {
  bankAccountType,
  doctypes,
  gender,
  subcategory,
} from '../../../constants/enums';

class DigitalSaleUnmaterializedPromissory extends PureComponent {
  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
    this._fetchLocations();
    this.props.fetchPointOfSaleInfo(this.props.posId)
    this.props.professions.length === 0 && this.props.fetchProfessions();
  }

  _fetchLocations = () => {
    this.props.neighbourhoodNames.length === 0 &&
      this.props.fetchLocationNames(
        {
          areaType: 5
        },
        'neighbourhoodNames'
      );
  };

  _getLocation = (object, key, param, list) => {
    const result = object[key]
      ? this.props[list].find(
        location =>
          object[key] === location.description || object[key] === location.id
      )
      : '';
    if (param) {
      return result[param];
    }
    return result;
  };

  initialValues = () => {
    return initialValuesSanitizer(this.props);
  };

  handleErrorBankFields = (name, values) => {
    const bankFields = ['bankName', 'bankAccountNumber', 'bankAccountType'];

    return bankFields.filter(field => field !== name).some(field => values[field] !== '') && !values[name];
  };

  getDocTypeValue = () => {
    const { debtorIdentificationType } = this.props;
    return doctypes.find(type => type.value === debtorIdentificationType).id;
  };

  onSubmit = values => {
    const { process, routes, contract } = this.props;
    const parsedValues = submitSanitizer(
      {
        ...values,
        newName: values.debtorName,
        newLastname: values.debtorLastname,
        professionName: this.props.professions.find(
          p => p.id == values.debtorProfession
        ).name
      },
      { ...this.props, promissory: null, isDigitalSale: true },
      this._getLocation
    );
    const tempPromissory = {
      ...parsedValues,
      promissory: {
        type_of_promissory: 'D',
        is_unmaterialized: true,
        contract_id: this.props.contract
      }
    };
    this.props.registerDigitalSalePromissory(
      { tempPromissory: tempPromissory },
      contract,
      routes[this.nextStep],
      process
    );
  };

  render() {
    const {
      classes,
      promissory,
      isSecureSale,
      neighbourhoodNames,
      professions = [],
      maritalStatus,
      houseType,
      studyLevels = [],
      cosignerContract,
      cosigner,
      b2bSelectedPos = [],
      posId,
      occupations
    } = this.props;

    const isB2bSale = b2bSelectedPos.find(pos => pos === posId.toString());
    if (neighbourhoodNames.length === 0 || professions.length === 0 || studyLevels.length === 0) {
      return <ActivityIndicator />;
    }
    return (
      <div>
        <Paper className={classes.container}>
          <Formik
            initialValues={{
              ...this.initialValues(),
              promissory
            }}
            validationSchema={cosigner ? debtorValidation(isSecureSale, isB2bSale).concat(cosignerValidation) : debtorValidation(isSecureSale, isB2bSale)}
            onSubmit={this.onSubmit}
            render={({
              values,
              handleChange,
              setFieldValue,
              errors,
              touched,
              handleSubmit
            }) => (
              <Form>
                <SegmentHeader className={classes.segmentHeader}>
                  {
                    'INFORMACIÓN PERSONAL DEL COMPRADOR'
                  }
                </SegmentHeader>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item md={4} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      name="debtorName"
                      label="Nombres del titular: *"
                      value={values.debtorName}
                      onChange={handleChange}
                      error={touched.debtorName && !!errors.debtorName}
                      helperText={touched.debtorName && errors.debtorName}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      name="debtorLastname"
                      label="Apellidos del titular:"
                      value={values.debtorLastname}
                      onChange={handleChange}
                      error={touched.debtorLastname && !!errors.debtorLastname}
                      helperText={
                        touched.debtorLastname && errors.debtorLastname
                      }
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <SimpleSelect
                      name="debtorMaritalStatus"
                      label="Estado civil:"
                      value={values.debtorMaritalStatus}
                      onChange={handleChange}
                      options={mapListToOptions(maritalStatus)}
                      error={
                        touched.debtorMaritalStatus &&
                        !!errors.debtorMaritalStatus
                      }
                      helperText={
                        touched.debtorMaritalStatus &&
                        errors.debtorMaritalStatus
                      }
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <SimpleSelect
                      disabled
                      name="debtorDoctype"
                      label="Tipo de documento:"
                      value={values.debtorDoctype}
                      onChange={handleChange}
                      options={mapListToOptions(doctypes)}
                      error={touched.debtorDoctype && !!errors.debtorDoctype}
                      helperText={touched.debtorDoctype && errors.debtorDoctype}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      name="debtorDocid"
                      label="Numero:"
                      value={values.debtorDocid}
                      onChange={handleChange}
                      error={touched.debtorDocid && !!errors.debtorDocid}
                      helperText={touched.debtorDocid && errors.debtorDocid}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      id="debtorDocExpeditionLocality"
                      name="debtorDocExpeditionLocality"
                      label="Lugar de expedición:"
                      value={values.debtorDocExpeditionLocality}
                      onChange={handleChange}
                      error={touched.debtorDocid && !!errors.debtorDocid}
                      helperText={touched.debtorDocid && errors.debtorDocid}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      id="debtorDocExpeditionDate"
                      name="debtorDocExpeditionDate"
                      label="Fecha de expedicion:"
                      value={values.debtorDocExpeditionDate}
                      onChange={handleChange}
                      error={touched.debtorDocid && !!errors.debtorDocid}
                      helperText={touched.debtorDocid && errors.debtorDocid}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OurDatePicker
                      name={'debtorDocBirthdate'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha de nacimiento:"
                      value={values.debtorDocBirthdate}
                      onChange={e => setFieldValue('debtorDocBirthdate', e)}
                      onError={() => setFieldValue('debtorDocBirthdate', null)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      error={
                        touched.debtorDocBirthdate &&
                        !!errors.debtorDocBirthdate
                      }
                      helperText={
                        touched.debtorDocBirthdate && errors.debtorDocBirthdate
                      }
                      disabled
                    />
                  </Grid>
                  {isSecureSale && (
                    <Grid item md={3} sm={6} xs={12}>
                      <OutlinedTextField
                        disabled={!!promissory}
                        name="debtorBirthPlace"
                        label="Lugar de nacimiento:"
                        value={values.debtorBirthPlace}
                        onChange={handleChange}
                        error={
                          touched.debtorBirthPlace &&
                          !!errors.debtorBirthPlace
                        }
                        helperText={
                          touched.debtorBirthPlace &&
                          errors.debtorBirthPlace
                        }
                      />
                    </Grid>
                  )}
                  <Grid item md={3} sm={6} xs={12}>
                    <SimpleSelect
                      name="debtorGender"
                      label="Sexo:"
                      value={values.debtorGender}
                      onChange={handleChange}
                      options={mapListToOptions(gender)}
                      error={touched.debtorGender && !!errors.debtorGender}
                      helperText={touched.debtorGender && errors.debtorGender}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <SimpleSelect
                      name="debtorFormationLevel"
                      label="Nivel de estudio:"
                      value={values.debtorFormationLevel}
                      onChange={handleChange}
                      options={mapListToOptions(studyLevels)}
                      error={
                        touched.debtorFormationLevel &&
                        !!errors.debtorFormationLevel
                      }
                      helperText={
                        touched.debtorFormationLevel &&
                        errors.debtorFormationLevel
                      }
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      name="debtorEmail"
                      label="Correo electronico:"
                      value={values.debtorEmail}
                      onChange={handleChange}
                      error={touched.debtorEmail && !!errors.debtorEmail}
                      helperText={touched.debtorEmail && errors.debtorEmail}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      name="debtorPhone"
                      label="Telefono:"
                      value={values.debtorPhone}
                      onChange={handleChange}
                      error={touched.debtorPhone && !!errors.debtorPhone}
                      helperText={touched.debtorPhone && errors.debtorPhone}
                      type={'number'}
                      min={0}
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 7);
                      }}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      name="debtorCellphone"
                      label="Telefono celular:"
                      value={values.debtorCellphone}
                      onChange={handleChange}
                      error={
                        touched.debtorCellphone && !!errors.debtorCellphone
                      }
                      helperText={
                        touched.debtorCellphone && errors.debtorCellphone
                      }
                      type={'number'}
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      min={0}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Field
                      disabled
                      name="debtorNeighbourhood"
                      id="debtorNeighbourhood"
                      label="Barrio:"
                      margin="normal"
                      variant="outlined"
                      attr="description"
                      value={values.debtorNeighbourhood}
                      initial_value={values.debtorNeighbourhood}
                      error={
                        touched.debtorNeighbourhood &&
                        !!errors.debtorNeighbourhood
                      }
                      helperText={
                        touched.debtorNeighbourhood &&
                        errors.debtorNeighbourhood
                      }
                      onChange={handleChange}
                      suggestions={neighbourhoodNames}
                      component={AutoSuggestInput}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <OutlinedTextField
                      disabled
                      name="debtorAddress"
                      label="Direccion de residencia:"
                      value={values.debtorAddress}
                      onChange={handleChange}
                      error={touched.debtorAddress && !!errors.debtorAddress}
                      helperText={touched.debtorAddress && errors.debtorAddress}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <SimpleSelect
                      name="debtorHouseType"
                      label="Tipo de vivienda:"
                      value={values.debtorHouseType}
                      onChange={handleChange}
                      options={mapListToOptions(houseType)}
                      error={
                        touched.debtorHouseType && !!errors.debtorHouseType
                      }
                      helperText={
                        touched.debtorHouseType && errors.debtorHouseType
                      }
                    />
                  </Grid>
                </Grid>
                <SegmentHeader className={classes.segmentHeader}>
                  INFORMACIÓN LABORAL DEL DEUDOR
                </SegmentHeader>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item md={3} sm={6} xs={12}>
                    <Field
                      name="debtorProfession"
                      id="debtorProfession"
                      label="Profesión:"
                      margin="normal"
                      variant="outlined"
                      attr="label"
                      value={values.debtorProfession}
                      initial_value={values.debtorProfessionLabel}
                      error={
                        touched.debtorProfession && !!errors.debtorProfession
                      }
                      helperText={
                        touched.debtorProfession && errors.debtorProfession
                      }
                      onChange={handleChange}
                      suggestions={mapListToOptions(professions, {
                        raw: false,
                        property: 'name',
                        withId: false
                      })}
                      component={AutoSuggestInput}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <SimpleSelect
                      name="debtorOccupation"
                      label="Ocupación:"
                      value={values.debtorOccupation}
                      onChange={handleChange}
                      options={mapListToOptions(occupations, {}, true)}
                      error={
                        touched.debtorOccupation && !!errors.debtorOccupation
                      }
                      helperText={
                        touched.debtorOccupation && errors.debtorOccupation
                      }
                    />
                  </Grid>
                  {values.debtorProfession === 689 && (
                    <Grid item md={3} sm={6} xs={12}>
                      <OutlinedTextField
                        name="debtorCompany"
                        label="Empresa:"
                        value={values.debtorCompany}
                        onChange={handleChange}
                        error={touched.debtorCompany && !!errors.debtorCompany}
                        helperText={
                          touched.debtorCompany && errors.debtorCompany
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                {isSecureSale && (
                  <>
                    <SegmentHeader className={classes.segmentHeader}>
                      INFORMACIÓN BANCARIA (OPCIONAL)
                    </SegmentHeader>
                    <Grid container spacing={24} alignItems="flex-start">
                      <Grid item md={3} sm={6} xs={12}>
                        <OutlinedTextField
                          disabled={!!promissory}
                          name="bankName"
                          label="Banco:"
                          value={values.bankName}
                          onChange={handleChange}
                          error={this.handleErrorBankFields('bankName', values)}
                          helperText={this.handleErrorBankFields('bankName', values) && 'El campo es requerido'}
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <OutlinedTextField
                          disabled={!!promissory}
                          name="bankAccountNumber"
                          label="Numero de cuenta:"
                          value={values.bankAccountNumber}
                          onChange={handleChange}
                          error={this.handleErrorBankFields('bankAccountNumber', values)}
                          helperText={this.handleErrorBankFields('bankAccountNumber', values) && 'El campo es requerido'}
                          type="number"
                          min={0}
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <SimpleSelect
                          disabled={!!promissory}
                          name="bankAccountType"
                          label="Tipo de cuenta:"
                          value={values.bankAccountType}
                          onChange={handleChange}
                          options={mapListToOptions(bankAccountType)}
                          error={this.handleErrorBankFields('bankAccountType', values)}
                          helperText={this.handleErrorBankFields('bankAccountType', values) ? 'El campo es requerido' : ''}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {(_get(promissory, 'cosigner', null) ||
                  values.cosignerDocid) && (
                    <React.Fragment>
                      <SegmentHeader className={classes.segmentHeader}>
                        INFORMACION DEL CODEUDOR
                      </SegmentHeader>
                      <Grid container spacing={24} alignItems="flex-start">
                        <Grid item md={4} sm={6} xs={12}>
                          <OutlinedTextField
                            disabled
                            name="cosignerContract"
                            label="No. de contrato:"
                            value={values.cosignerContract}
                            onChange={handleChange}
                            error={
                              touched.cosignerContract &&
                              !!errors.cosignerContract
                            }
                            helperText={
                              touched.cosignerContract && errors.cosignerContract
                            }
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <OutlinedTextField
                            disabled
                            name="cosignerName"
                            label="Nombres del codeudor:"
                            value={values.cosignerName}
                            onChange={handleChange}
                            error={touched.cosignerName && !!errors.cosignerName}
                            helperText={
                              touched.cosignerName && errors.cosignerName
                            }
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <OutlinedTextField
                            disabled
                            name="cosignerLastname"
                            label="Apellidos del codeudor:"
                            value={values.cosignerLastname}
                            onChange={handleChange}
                            error={
                              touched.cosignerLastname &&
                              !!errors.cosignerLastname
                            }
                            helperText={
                              touched.cosignerLastname && errors.cosignerLastname
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <SimpleSelect
                            disabled
                            name="cosignerDoctype"
                            label="Tipo de documento:"
                            value={values.cosignerDoctype}
                            onChange={handleChange}
                            options={mapListToOptions(doctypes)}
                            error={
                              touched.cosignerDoctype && !!errors.cosignerDoctype
                            }
                            helperText={
                              touched.cosignerDoctype && errors.cosignerDoctype
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <OutlinedTextField
                            disabled
                            name="cosignerDocid"
                            label="Número:"
                            value={values.cosignerDocid}
                            onChange={handleChange}
                            error={
                              touched.cosignerDocid && !!errors.cosignerDocid
                            }
                            helperText={
                              touched.cosignerDocid && errors.cosignerDocid
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Field
                            disabled
                            name="cosignerDocExpeditionLocality"
                            id="cosignerDocExpeditionLocality"
                            label="Lugar de expedición:"
                            margin="normal"
                            variant="outlined"
                            attr="description"
                            value={values.cosignerDocExpeditionLocality}
                            initial_value={values.cosignerDocExpeditionLocality}
                            error={
                              touched.cosignerDocExpeditionLocality &&
                              !!errors.cosignerDocExpeditionLocality
                            }
                            helperText={
                              touched.cosignerDocExpeditionLocality &&
                              errors.cosignerDocExpeditionLocality
                            }
                            onChange={handleChange}
                            component={OutlinedTextField}
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <OurDatePicker
                            disabled
                            name={'cosignerDocExpeditionDate'}
                            invalidLabel="Fecha inválida"
                            maxDateMessage="Fecha inválida"
                            minDateMessage="Fecha inválida"
                            invalidDateMessage={'Fecha inválida'}
                            label="Fecha de expedicion:"
                            value={values.cosignerDocExpeditionDate}
                            onChange={e =>
                              setFieldValue('cosignerDocExpeditionDate', e)
                            }
                            onError={() =>
                              setFieldValue('cosignerDocExpeditionDate', null)
                            }
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            error={
                              touched.cosignerDocExpeditionDate &&
                              !!errors.cosignerDocExpeditionDate
                            }
                            helperText={
                              touched.cosignerDocExpeditionDate &&
                              errors.cosignerDocExpeditionDate
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <SimpleSelect
                            disabled={
                              _get(promissory, 'cosigner', null) && !isTransfer
                            }
                            name="cosignerFormationLevel"
                            label="Nivel de estudio:"
                            value={values.cosignerFormationLevel}
                            onChange={handleChange}
                            options={mapListToOptions(studyLevels)}
                            error={
                              touched.cosignerFormationLevel &&
                              !!errors.cosignerFormationLevel
                            }
                            helperText={
                              touched.cosignerFormationLevel &&
                              errors.cosignerFormationLevel
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <OutlinedTextField
                            disabled={
                              _get(promissory, 'cosigner', null) && !isTransfer
                            }
                            name="cosignerPhone"
                            label="Teléfono:"
                            value={values.cosignerPhone}
                            onChange={handleChange}
                            error={
                              touched.cosignerPhone && !!errors.cosignerPhone
                            }
                            helperText={
                              touched.cosignerPhone && errors.cosignerPhone
                            }
                            type={'number'}
                            min={0}
                            onInput={e => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 7);
                            }}
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <OutlinedTextField
                            disabled={
                              _get(promissory, 'cosigner', null) && !isTransfer
                            }
                            name="cosignerCellphone"
                            label="Teléfono celular:"
                            value={values.cosignerCellphone}
                            onChange={handleChange}
                            error={
                              touched.cosignerCellphone &&
                              !!errors.cosignerCellphone
                            }
                            helperText={
                              touched.cosignerCellphone &&
                              errors.cosignerCellphone
                            }
                            type={'number'}
                            onInput={e => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                            min={0}
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <SimpleSelect
                            disabled
                            name="cosignerStratum"
                            label="Estrato:"
                            value={values.cosignerStratum}
                            onChange={handleChange}
                            options={mapListToOptions(subcategory)}
                            error={
                              touched.cosignerStratum && !!errors.cosignerStratum
                            }
                            helperText={
                              touched.cosignerStratum && errors.cosignerStratum
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Field
                            disabled
                            name="cosignerNeighbourhood"
                            id="cosignerNeighbourhood"
                            label="Barrio:"
                            margin="normal"
                            variant="outlined"
                            attr="description"
                            value={values.cosignerNeighbourhoodId}
                            initial_value={values.cosignerNeighbourhood}
                            error={
                              touched.cosignerNeighbourhood &&
                              !!errors.cosignerNeighbourhood
                            }
                            helperText={
                              touched.cosignerNeighbourhood &&
                              errors.cosignerNeighbourhood
                            }
                            onChange={handleChange}
                            suggestions={neighbourhoodNames}
                            component={AutoSuggestInput}
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <OutlinedTextField
                            disabled={
                              (_get(promissory, 'cosigner', null) &&
                                !isTransfer) ||
                              cosignerContract &&
                              _get(cosigner, 'address', null)
                            }
                            name="cosignerAddress"
                            label="Direccion de residencia:"
                            value={values.cosignerAddress}
                            onChange={handleChange}
                            error={
                              touched.cosignerAddress && !!errors.cosignerAddress
                            }
                            helperText={
                              touched.cosignerAddress && errors.cosignerAddress
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <SimpleSelect
                            disabled={
                              _get(promissory, 'cosigner', null) && !isTransfer
                            }
                            name="cosignerHouseType"
                            label="Tipo de vivienda:"
                            value={values.cosignerHouseType}
                            onChange={handleChange}
                            options={mapListToOptions(houseType)}
                            error={
                              touched.cosignerHouseType &&
                              !!errors.cosignerHouseType
                            }
                            helperText={
                              touched.cosignerHouseType &&
                              errors.cosignerHouseType
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <SimpleSelect
                            disabled={
                              _get(promissory, 'cosigner', null) && !isTransfer
                            }
                            name="cosignerGender"
                            label="Sexo:"
                            value={values.cosignerGender}
                            onChange={handleChange}
                            options={mapListToOptions(gender)}
                            error={
                              touched.cosignerGender && !!errors.cosignerGender
                            }
                            helperText={
                              touched.cosignerGender && errors.cosignerGender
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <SimpleSelect
                            disabled={
                              _get(promissory, 'cosigner', null) && !isTransfer
                            }
                            name="cosignerMaritalStatus"
                            label="Estado civil:"
                            value={values.cosignerMaritalStatus}
                            onChange={handleChange}
                            options={mapListToOptions(maritalStatus)}
                            error={
                              touched.cosignerMaritalStatus &&
                              !!errors.cosignerMaritalStatus
                            }
                            helperText={
                              touched.cosignerMaritalStatus &&
                              errors.cosignerMaritalStatus
                            }
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <OutlinedTextField
                            disabled={
                              _get(promissory, 'cosigner', null) && !isTransfer
                            }
                            name="cosignerEmail"
                            label="Correo electrónico:"
                            value={values.cosignerEmail}
                            onChange={handleChange}
                            error={
                              touched.cosignerEmail && !!errors.cosignerEmail
                            }
                            helperText={
                              touched.cosignerEmail && errors.cosignerEmail
                            }
                          />
                        </Grid>
                      </Grid>
                      <SegmentHeader className={classes.segmentHeader}>
                        INFORMACION LABORAL DEL CODEUDOR
                      </SegmentHeader>
                      <Grid container spacing={24} alignItems="flex-start">
                        <Grid item md={3} sm={6} xs={12}>
                          <Field
                            disabled={
                              _get(promissory, 'cosigner', null) && !isTransfer
                            }
                            name="cosignerProfession"
                            id="cosignerProfession"
                            label="Profesión:"
                            margin="normal"
                            variant="outlined"
                            attr="label"
                            value={values.cosignerProfession}
                            initial_value={values.cosignerProfessionLabel}
                            error={
                              touched.cosignerProfession &&
                              !!errors.cosignerProfession
                            }
                            helperText={
                              touched.cosignerProfession &&
                              errors.cosignerProfession
                            }
                            onChange={handleChange}
                            suggestions={mapListToOptions(professions, {
                              raw: false,
                              property: 'name',
                              withId: false
                            })}
                            component={AutoSuggestInput}
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          {(_get(promissory, 'cosigner', null) && !isTransfer) ? (
                            <OutlinedTextField
                              disabled
                              name="cosignerOccupation"
                              label="Ocupación:"
                              value={values.cosignerOccupation}
                              onChange={handleChange}
                              error={
                                touched.cosignerOccupation &&
                                !!errors.cosignerOccupation
                              }
                              helperText={
                                touched.cosignerOccupation &&
                                errors.cosignerOccupation
                              }
                            />
                          ) : (
                            <SimpleSelect
                              name="cosignerOccupation"
                              label="Ocupación:"
                              value={values.cosignerOccupation}
                              onChange={handleChange}
                              options={mapListToOptions(occupations, {}, true)}
                              error={
                                touched.cosignerOccupation &&
                                !!errors.cosignerOccupation
                              }
                              helperText={
                                touched.cosignerOccupation &&
                                errors.cosignerOccupation
                              }
                            />
                          )}
                        </Grid>
                        {values.cosignerProfession === 689 && (
                          <Grid item md={3} sm={6} xs={12}>
                            <OutlinedTextField
                              disabled={
                                _get(promissory, 'cosigner', null) && !isTransfer
                              }
                              name="cosignerCompany"
                              label="Empresa:"
                              value={values.cosignerCompany}
                              onChange={handleChange}
                              error={
                                touched.cosignerCompany &&
                                !!errors.cosignerCompany
                              }
                              helperText={
                                touched.cosignerCompany && errors.cosignerCompany
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </React.Fragment>
                  )}
                <StepperButtons
                  onSubmit={handleSubmit}
                  goBack={this.props.goBack}
                />
              </Form>
            )}
          />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 2 * theme.spacing.unit
  },
  button: {
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    marginTop: 2 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  checkboxLabel: {
    marginTop: 16,
    marginBottom: 8
  }
});

DigitalSaleUnmaterializedPromissory.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(DigitalSaleUnmaterializedPromissory);
