import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupplierProposalsList from './SupplierProposalsList';
import {
  fetchSupplierProposals,
  goToProposal,
  goToCreatePage,
} from '../../../actions/supplier_proposal_actions';

function mapStateToProps(state) {
  return {
    currentUser: state.authentication.currentUser,
    proposals: state.supplierProposal.proposalsList,
    fetchingProposals: state.requestState.requestSupplierProposalListSend,
    totalCount: state.supplierProposal.metaData && state.supplierProposal.metaData.totalCount,
    currentPage: state.supplierProposal.metaData && state.supplierProposal.metaData.currentPage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSupplierProposals: bindActionCreators(fetchSupplierProposals, dispatch),
    goToProposal: bindActionCreators(goToProposal, dispatch),
    goToCreatePage: bindActionCreators(goToCreatePage, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierProposalsList);