import * as Yup from 'yup';

const lastNames = /^[a-z áéíóúüñ']+(\s*[a-z áéíóúüñ'\-]*)*[a-z áéíóúüñ'\s]+$/i;
const noSpecialCharsRegex = /^[^\d,.;:]*$/;

export const debtorValidation = (isSecureSale, isB2bSale) => Yup.object().shape({
  debtorName: Yup.string()
  .required('El campo es requerido')
  .matches(noSpecialCharsRegex, 'Caracteres no permitidos.'),
  debtorMaritalStatus: Yup.number().required('El campo es requerido'),
  debtorDoctype: Yup.number().required('El campo es requerido'),
  debtorDocid: Yup.number().required('El campo es requerido'),
  debtorDocExpeditionLocality: Yup.string().required('El campo es requerido'),
  debtorDocExpeditionDate: Yup.mixed().required('El campo es requerido'),
  debtorDocBirthdate: Yup.mixed().required('El campo es requerido'),
  debtorGender: Yup.number().required('El campo es requerido'),
  debtorFormationLevel: Yup.number().required('El campo es requerido'),
  debtorEmail: isB2bSale ? Yup.string()
    .required('El campo es requerido')
    .email("Debe ingresar un email válido.") : Yup.string()
    .nullable()
    .email("Debe ingresar un email válido."),
  debtorPhone: Yup.string().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos.'
  ),
  debtorCellphone: Yup.string()
    .required('El campo es requerido')
    .test('len', 'Debe tener 10 dígitos.', val => val && val.length === 10)
    .test('equal', 'El número de celular debe ser diferente al del codeudor.',
      function (v) { return v !== this.resolve(Yup.ref('cosignerCellphone')); }
    ),
  debtorNeighbourhood: Yup.mixed().required('El campo es requerido'),
  debtorAddress: Yup.string().required('El campo es requerido'),
  debtorHouseType: Yup.number().required('El campo es requerido'),
  debtorProfession: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa una profesión válida.'),
  debtorOccupation: Yup.string().required('El campo es requerido'),
  debtorCompany: Yup.string().when('debtorProfession', {
    is: 689,
    then: Yup.string()
      .required('El campo es requerido')
      .min(1, 'Ingresa un nombre válido.')
  }),
  debtorBirthPlace: isSecureSale ? Yup.string()
    .required('El campo es requerido')
    .test('len', 'El nombre debe estar entre 4 y 50 caracteres.', (val) => val && val.toString().length <= 50 && val.toString().length >= 4)
    .matches(lastNames, 'No se permiten caracteres especiales o números.')
    : Yup.string()
});

export const cosignerValidation = Yup.object().shape({
  cosignerContract: Yup.number().nullable(),
  cosignerName: Yup.string()
    .required('El campo es requerido')
    .matches(noSpecialCharsRegex, 'Caracteres no permitidos.'),
  cosignerLastname: Yup.string()
    .required('El campo es requerido')
    .matches(noSpecialCharsRegex, 'Caracteres no permitidos.'),
  cosignerMaritalStatus: Yup.number().required('El campo es requerido'),
  cosignerDoctype: Yup.number().required('El campo es requerido'),
  cosignerDocid: Yup.string()
    .when('cosignerDoctype', {
      is: 'Cédula',
      then: Yup.string()
        .matches(
          /^[0-9]+$/,
          'Tipo de documento no admite caracteres especiales o letras'
        )
        .required('El campo es requerido')
    })
    .when('cosignerDoctype', {
      is: 'Pasaporte',
      then: Yup.string()
        .matches(
          /^[a-z-A-Z-0-9]+$/,
          'Tipo de documento no admite caracteres especiales'
        )
        .required('El campo es requerido')
    })
    .when('cosignerDoctype', {
      is: 'Cédula de Extranjería',
      then: Yup.string()
        .matches(
          /^[a-z-A-Z-0-9]+$/,
          'Tipo de documento no admite caracteres especiales'
        )
        .required('El campo es requerido')
    })
    .when('cosignerDoctype', {
      is: 'Nit / Rut',
      then: Yup.string()
        .matches(
          /^[0-9-]+$/,
          'Tipo de documento solo admite números y el caracter - '
        )
        .required('El campo es requerido')
    }),
  cosignerDocExpeditionLocality: Yup.string().required('El campo es requerido'),
  cosignerDocExpeditionDate: Yup.mixed().required('El campo es requerido'),
  cosignerGender: Yup.number().required('El campo es requerido'),
  cosignerFormationLevel: Yup.number().required('El campo es requerido'),
  cosignerEmail: Yup.string()
    .nullable()
    .email('Debe ingresar un email válido.')
    .when(["debtorEmail"], (debtorEmail, schema) => {
      return debtorEmail && schema.notOneOf([debtorEmail], "No puede ser el mismo correo que el del deudor");
    }),

  cosignerPhone: Yup.string().matches(
    /^([0-9]{7})$/,
    'El número ingresado debe tener 7 dígitos.'
  ),
  cosignerCellphone: Yup.string()
    .required('El campo es requerido')
    .matches(/^([0-9]{10})$/, 'El número ingresado debe tener 10 dígitos.')
    .test('equal', 'El número de celular debe ser diferente al del deudor.',
      function (v) { return v !== this.resolve(Yup.ref('debtorCellphone')); }
    ),
  cosignerNeighbourhood: Yup.mixed().required('El campo es requerido'),
  cosignerAddress: Yup.string().required('El campo es requerido'),
  cosignerHouseType: Yup.number().required('El campo es requerido'),
  cosignerProfession: Yup.number()
    .required('El campo es requerido')
    .min(1, 'Ingresa una profesión válida.'),
  cosignerOccupation: Yup.string().required('El campo es requerido'),
  cosignerCompany: Yup.string().when('cosignerProfession', {
    is: 689,
    then: Yup.string()
      .required('El campo es requerido')
      .min(1, 'Ingresa un nombre válido.')
  }),
  cosignerStratum: Yup.number().required('El campo es requerido')
});