import {
  OTP_SEND_RESPONSE,
  OTP_VALIDATION_RESPONSE,
  RESET_OTP_INFO_RESPONSE,
  VALIDATE_UNIQUE_MOBILE,
  UPDATE_VALIDATED_FIELD_NAME
} from '../constants/otp_validation_constants';
import RequestService from '../services/request_service';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { newAlert, serverStatusError } from './alert_actions';
import { sendOtpSanitizer, validateOtpSanitizer, validateUniqueMobileSanitizer } from '../helpers/sanitizers/otpSanitizer';

const requestService = new RequestService('node');

function setValidateUniqueMobileResponseInfo(data) {
  return {
    type: VALIDATE_UNIQUE_MOBILE,
    data
  };
}

function setOtpSendResponseInfo(data) {
  return {
    type: OTP_SEND_RESPONSE,
    data
  };
}

function setOtpValidationResponseInfo(data) {
  return {
    type: OTP_VALIDATION_RESPONSE,
    data
  };
}

export const resetOtpInfoResponse = () => {
  return {
    type: RESET_OTP_INFO_RESPONSE
  };
}

function updateValidatedFieldName(data) {
  return {
    type: UPDATE_VALIDATED_FIELD_NAME,
    data
  };
}

export const validateUniqueMobile = (
  params = {}
) => async dispatch => {
  dispatch(sendRequest('uniqueMobile'));
  try {
    const payload = await requestService.post('phone-code/validate-unique', validateUniqueMobileSanitizer(params), {}, true);
    dispatch(setValidateUniqueMobileResponseInfo(payload.data.valid));
    if (!payload.data.valid) {
      dispatch(newAlert('error', 'ERROR:', 'El número ingresado ya ha sido utilizado en otro pagaré'));
    }
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('uniqueMobile', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('uniqueMobile'));
  }
};

export const sendOtp = (
  params = {}
) => async dispatch => {
  dispatch(sendRequest('Otp'));
  dispatch(updateValidatedFieldName(params.validatedFieldName))
  try {
    delete params.validatedFieldName;
    const payload = await requestService.post('phone-code/send-otp', params, {}, true);
    dispatch(setOtpSendResponseInfo({
      mobile: params.mobile,
      validationSendResponse: payload.data.valid
    }));
    dispatch(newAlert('success', 's', payload.data.message));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('Otp', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('Otp'));
  }
};

export const validateOtp = (params = {}) => async dispatch => {
  dispatch(sendRequest('ValidateOtp'));
  dispatch(updateValidatedFieldName(params.validatedFieldName))
  try {
    delete params.validatedFieldName;
    const payload = await requestService.post(
      'phone-code/validate-otp',
      validateOtpSanitizer(params),
      {},
      true
    );
    dispatch(setOtpValidationResponseInfo({
      mobileConfirmedAt: payload.data.confirmedAt,
      validationConfirmationResponse: payload.data.valid
    }));
    if (payload.data.valid) {
      dispatch(newAlert('success', 's', payload.data.message));
      return {
        valid: true,
      };
    } else {
      dispatch(newAlert('error', 'ERROR:', payload.data.message));
      return {
        valid: false,
      };
    }
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('ValidateOtp', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('ValidateOtp'));
  }
};
