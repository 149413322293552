import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import {
  SET_PROPOSALS_LIST,
  SET_PROPOSAL_TO_SHOW,
  SET_CREATION_FORM_DATA,
  SET_ADD_DOCUMENT_MODAL_OPEN,
  SET_PRODUCT_MODAL_OPEN,
  SET_SIGNER_MODAL_OPEN,
  SET_LINE_EXECUTIVES
} from '../constants/supplier_proposal_constants';
import { history } from '../helpers/history';
import RequestService from '../services/request_service';
import { newAlert } from './alert_actions';
import { supplierProposalsFilterSanitizer } from '../helpers/sanitizers/supplierProposalsSanitizer';
import { stringifyQuery } from '../helpers/parseQuerystring';

const requestService = new RequestService('node');

function setProposalsList(data) {
  return {
    type: SET_PROPOSALS_LIST,
    data
  };
}

function setProposalToShow(data) {
  return {
    type: SET_PROPOSAL_TO_SHOW,
    data
  };
}

function setCreationFormData(data) {
  return {
    type: SET_CREATION_FORM_DATA,
    data
  };
}

function _setAddDocumentModalOpen(value) {
  return {
    type: SET_ADD_DOCUMENT_MODAL_OPEN,
    data: value
  };
}

function _setProductModalOpen(open, product = {}) {
  return {
    type: SET_PRODUCT_MODAL_OPEN,
    data: {
      open,
      product
    }
  };
}

function _setSignerModalOpen(open, signer = {}) {
  return {
    type: SET_SIGNER_MODAL_OPEN,
    data: {
      open,
      signer
    }
  };
}

function _setLineExecutives(lineExecutives) {
  return {
    type: SET_LINE_EXECUTIVES,
    data: { lineExecutives }
  };
}

export const saveData = (function() {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function(base64String, fileName) {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

export const fetchSupplierProposals = (params = {}) => async dispatch => {
  dispatch(sendRequest('SupplierProposalList'));
  history.push(`/supplier-proposals?${stringifyQuery(params)}`);
  try {
    if (params.status === 'todos') {
      params.status = null
    }

    const payload = await requestService.get(
      'supplier_proposals',
      supplierProposalsFilterSanitizer(params),
      true
    );
    const proposals = payload.data.proposals;
    const metaData = payload.data.metaData;
    dispatch(setProposalsList({ proposals, metaData }));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('SupplierProposalList'));
  }
};

export const fetchProposal = params => async dispatch => {
  dispatch(sendRequest('SupplierProposalShow'));
  try {
    const payload = await requestService.get('supplier_proposals/' + params.id);
    const proposal = payload.data.proposal;
    dispatch(setProposalToShow({ proposal }));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('SupplierProposalShow'));
  }
};

export const createProposal = params => async dispatch => {
  dispatch(setCreationFormData({ action: params.action }));
  dispatch(sendRequest('SupplierProposalCreate'));
  try {
    const payload = await requestService.post(
      'supplier_proposals',
      params.values
    );
    const proposal = payload.data.proposal;
    dispatch(setProposalToShow({ proposal }));
    if (
      typeof params.onComplete !== 'undefined' &&
      params.onComplete !== null
    ) {
      params.onComplete(proposal.documents, proposal.id);
    } else {
      const activeStep =
        params.action === 'save' ? params.activeStep : params.activeStep + 1;
      const alertMessage =
        params.action === 'save'
          ? 'Propuesta creada exitosamente, puedes salir y tus cambios no se perderan'
          : 'Propuesta creada exitosamente';
      dispatch(setCreationFormData({ activeStep }));
      history.push('/supplier-proposals/' + proposal.id);
      dispatch(newAlert('success', 'sucess', alertMessage));
    }
  } catch (errors) {
    console.error(errors);
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('SupplierProposalCreate'));
  }
};

export const updateProposal = params => async dispatch => {
  dispatch(setCreationFormData({ action: params.action }));
  dispatch(sendRequest('SupplierProposalUpdate'));
  try {
    delete params['values']['generalDataDocuments'];
    const payload = await requestService.put(
      'supplier_proposals/' + params.id,
      params.values
    );
    const proposal = payload.data.proposal;
    dispatch(setProposalToShow({ proposal }));
    const activeStep =
      params.action === 'save'
        ? params.activeStep
        : params.activeStep === params.maxStep
        ? params.activeStep
        : params.activeStep + 1;
    const alertMessage =
      params.action === 'save'
        ? 'Propuesta actualizada exitosamente, puedes salir y tus cambios no se perderan'
        : 'Propuesta actualizada exitosamente';
    dispatch(setCreationFormData({ activeStep }));
    params.updateValues(proposal);
    dispatch(newAlert('success', 'sucess', alertMessage));
    if (params.action === 'continue' && params.activeStep === params.maxStep) {
      goToIndex()(dispatch);
    }
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('SupplierProposalUpdate'));
  }
};

export const setCreationFormActiveStep = step => async dispatch => {
  dispatch(setCreationFormData({ activeStep: step }));
};

export const goToCreatePage = () => async dispatch => {
  dispatch(setProposalToShow({ proposal: {} }));
  dispatch(setCreationFormData({ activeStep: 0 }));
  history.push('/supplier-proposals-creation');
};

export const goToProposal = params => async dispatch => {
  dispatch(setCreationFormData({ activeStep: 0 }));
  history.push('/supplier-proposals/' + params.id);
};

export const goToIndex = () => async dispatch => {
  history.push('/supplier-proposals');
};

export const createDocument = params => async dispatch => {
  dispatch(sendRequest('CreateSupplierProposalDocument'));
  try {
    const payload = await requestService.post('supplier_proposal_documents', {
      document: params.document,
      documents: params.documents
    });
    const documents = payload.data.documents;
    params.setDocuments(documents);
    dispatch(_setAddDocumentModalOpen(false));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('CreateSupplierProposalDocument'));
  }
};

export const setAddDocumentModalOpen = open => async dispatch => {
  dispatch(_setAddDocumentModalOpen(open));
};

export const setProductModalOpen = (open, product = {}) => async dispatch => {
  dispatch(_setProductModalOpen(open, product));
};

export const setSignerModalOpen = (open, signer = {}) => async dispatch => {
  dispatch(_setSignerModalOpen(open, signer));
};

export const updateDocument = params => async dispatch => {
  try {
    const payload = await requestService.put(
      'supplier_proposal_documents/' + params.document.id,
      {
        document: params.document,
        documents: params.documents
      }
    );
    const documents = payload.data.documents;
    params.setDocuments(documents);
    if (params.isOnCreateProposal) {
      dispatch(setCreationFormData({ activeStep: params.activeStep }));
      history.push('/supplier-proposals/' + params.proposalId);
      dispatch(
        newAlert(
          'success',
          'sucess',
          'Propuesta creada exitosamente, puedes salir y tus cambios no se perderan'
        )
      );
    }
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  }
};

export const fetchDocument = params => async dispatch => {
  try {
    const payload = await requestService.get(
      'supplier_proposal_documents/' + params.documentId
    );
    const document = payload.data.document;
    saveData(document.base64, document.originalFileName);
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    params.removeLoadingDocumentId();
  }
};

export const createProduct = params => async dispatch => {
  dispatch(sendRequest('CreateSupplierProposalProduct'));
  try {
    const payload = await requestService.post(
      'supplier_proposal_products',
      params.product
    );
    const product = payload.data.product;
    params.setProducts(product);
    dispatch(_setProductModalOpen(false));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('CreateSupplierProposalProduct'));
  }
};

export const updateProduct = params => async dispatch => {
  dispatch(sendRequest('UpdateSupplierProposalProduct'));
  try {
    const payload = await requestService.put(
      'supplier_proposal_products/' + params.product.id,
      params.product
    );
    const product = payload.data.product;
    params.setProducts(product);
    dispatch(_setProductModalOpen(false));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('UpdateSupplierProposalProduct'));
  }
};

export const createSigner = params => async dispatch => {
  dispatch(sendRequest('CreateSupplierProposalSigner'));
  try {
    const payload = await requestService.post(
      'supplier_proposal_signers',
      params.signer
    );
    const signer = payload.data.signer;
    params.setSigners(signer);
    dispatch(_setSignerModalOpen(false));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('CreateSupplierProposalSigner'));
  }
};

export const updateSigner = params => async dispatch => {
  dispatch(sendRequest('UpdateSupplierProposalSigner'));
  try {
    const payload = await requestService.put(
      'supplier_proposal_signers/' + params.signer.id,
      params.signer
    );
    const signer = payload.data.signer;
    params.setSigners(signer);
    dispatch(_setSignerModalOpen(false));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('UpdateSupplierProposalSigner'));
  }
};

export const fetchLineExecutivesAndCoordinators = params => async dispatch => {
  dispatch(sendRequest('LineExecutivesAndCoordinators'));
  try {
    const payload = await requestService.get(
      'line_executives_and_coordinators'
    );
    const lineExecutives = payload.data.lineExecutives;
    dispatch(_setLineExecutives(lineExecutives));
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('LineExecutivesAndCoordinators'));
  }
};

export const updateLineExecutive = params => async dispatch => {
  dispatch(sendRequest('UpdateSupplierProposalLineExecutive'));
  try {
    await requestService.put(
      `supplier_proposals/${params.proposalId}/set_line_executive`,
      { lineExecutiveId: params.lineExecutiveId }
    );
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('UpdateSupplierProposalLineExecutive'));
  }
};

export const deleteProposal = params => async dispatch => {
  dispatch(sendRequest('DeleteSupplierProposal'));
  try {
    await requestService.delete('supplier_proposals/' + params.id);
    history.push('/supplier-proposals');
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('DeleteSupplierProposal'));
  }
};

export const previewMinute = params => async dispatch => {
  const { id, minuteType, commission } = params;
  dispatch(sendRequest('PreviewSupplierProposalMinute'));
  try {
    const payload = await requestService.get(
      'supplier_proposals/' + id + '/preview_minute',
      { minuteType, commission }
    );
    const document = payload.data.minute;
    saveData(document.base64, document.originalFileName);
  } catch (errors) {
    const response = await errors.json();
    if (response) {
      const key = Object.keys(response.errors)[0];
      const message = response.errors[key];
      dispatch(newAlert('error', 'ERROR:', message));
    }
  } finally {
    dispatch(completeRequest('PreviewSupplierProposalMinute'));
  }
};
