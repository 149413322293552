import _pickBy from 'lodash/pickBy';

export const supplierProposalsFilterSanitizer = values => {
  const params = _pickBy(
    values,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};

export const supplierProposalsSaveSanitizer = values => {
  const params = _pickBy(
    values,
    filter => !!filter || filter === false
  );
  return {
    ...params
  };
};
