import * as Yup from 'yup';

const noSpecialCharsRegex = /^[^\d,.;:]*$/;

export const validationSchema = needsCosigner => {
  return Yup.object().shape({
    debtorLastName: Yup.string()
      .required('El campo es requerido')
      .matches(noSpecialCharsRegex, 'Caracteres no permitidos.'),
    cosignerLastName: needsCosigner
      ? Yup.string()
          .required('El campo es requerido')
          .matches(noSpecialCharsRegex, 'Caracteres no permitidos.')
      : Yup.string().default('')
  });
};

export const validationSchemaCause = () => {
  return Yup.object().shape({
    cause: Yup.string().required('El campo es requerido')
  });
};

export const validationSchemaAdditionalData = () => {
  return Yup.object().shape({
    lastName: Yup.string()
      .required('El campo es requerido')
      .matches(noSpecialCharsRegex, 'Caracteres no permitidos.'),
    name: Yup.string()
      .required('El campo es requerido')
      .matches(noSpecialCharsRegex, 'Caracteres no permitidos.'),
  });
};

export const validationSchemaAdditionalDataOTP = () => {
  return Yup.object().shape({
    lastName: Yup.string()
      .required('El campo es requerido')
      .matches(noSpecialCharsRegex, 'Caracteres no permitidos.'),
    name: Yup.string()
      .required('El campo es requerido')
      .matches(noSpecialCharsRegex, 'Caracteres no permitidos.'),
    email: Yup.string()
      .required('El campo es requerido')
      .email('El formato del correo no es correcto.'),
    mobile: Yup.string()
      .required('El campo es requerido')
      .matches(/^\d{10}$/, 'El formato del número celular no es correcto')
      .length(10, 'El número celular debe tener 10 dígitos.')
  });
};
