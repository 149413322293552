import {
  FINANCIAL_PLANS_SET,
  LOCATION_NAMES_SET,
  DETAILED_PLANS_SET,
  SAVED_DETAILED_PLAN,
  LOCATION_MAPPED_NAMES_SET
} from '../constants/action_constants';
import _get from 'lodash/get';
import { newAlert } from './alert_actions';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { financialPlanFilterSanitizer } from '../helpers/sanitizers/financialPlanSanitizer';
import {
  financialDetailsFilterSanitizer,
  listSanitizer
} from '../helpers/sanitizers/financialDetailsSanitizer';
import AxiosRequest from '../services/axios_request';
import RequestService from '../services/request_service';
const axiosRequest = new AxiosRequest();
const nodeRequest = new RequestService('node');

//* PURE ACTION FUNCTIONS
function setFinancialPlans(items, meta) {
  return {
    type: FINANCIAL_PLANS_SET,
    data: items,
    meta
  };
}

function setDetailedPlans(items, meta) {
  return {
    type: DETAILED_PLANS_SET,
    data: items,
    meta
  };
}

function setLocationNames(items, locationName) {
  return {
    type: LOCATION_NAMES_SET,
    data: items,
    locationName
  };
}

function setLocationMappedNames(items, locationName) {
  return {
    type: LOCATION_MAPPED_NAMES_SET,
    data: items,
    locationName
  };
}

function savedDetailedPlan() {
  return {
    type: SAVED_DETAILED_PLAN
  };
}

//* VIEW ACTION FUNCTIONS

export const fetchFinancialPlans = (params = {}) => async dispatch => {
  dispatch(sendRequest('FinancialPlans'));
  history.replace(`/financial-plans?${stringifyQuery(params)}`);
  try {
    const payload = await nodeRequest.get(
      'financial-plan',
      financialPlanFilterSanitizer(params)
    );
    dispatch(setFinancialPlans(payload.data, payload.meta));
  } catch (errors) {
    dispatch(errorOnRequest('FinancialPlans', errors));
    _get(errors, 'errors.base') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base));
  } finally {
    dispatch(completeRequest('FinancialPlans'));
  }
};

export const fetchLocationNames = (
  params = {},
  locationName = null
) => async dispatch => {
  dispatch(sendRequest('LocationNames'));
  try {
    const payload = await nodeRequest.get('geographic-location', params);
    dispatch(
      setLocationNames(
        listSanitizer(payload.data.geographicLocations),
        locationName
      )
    );
  } catch (errors) {
    dispatch(errorOnRequest('LocationNames', errors));
    _get(errors, 'errors.base') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base));
  } finally {
    dispatch(completeRequest('LocationNames'));
  }
};

export const fetchLocationMappedNames = (params = {}) => async dispatch => {
  dispatch(sendRequest('LocationNames'));
  try {
    const payload = await nodeRequest.get(
      'geographic-location/biometric-reader/locations',
      params
    );
    dispatch(
      setLocationMappedNames(
        listSanitizer(payload.data.geographicLocations),
        'locationMappedNames'
      )
    );
  } catch (errors) {
    dispatch(errorOnRequest('LocationNames', errors));
    _get(errors, 'errors.base') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base));
  } finally {
    dispatch(completeRequest('LocationNames'));
  }
};

export const fetchDetailedPlans = (params = {}) => async dispatch => {
  dispatch(sendRequest('FinancialDetails'));
  history.replace(`/financial-details?${stringifyQuery(params)}`);
  try {
    const payload = await nodeRequest.get(
      'financing-plan-to-categories',
      financialDetailsFilterSanitizer(params)
    );
    dispatch(setDetailedPlans(payload.data, payload.meta));
  } catch (errors) {
    dispatch(
      newAlert(
        'error',
        'ERROR:',
        'No se pudieron cargar los detalles de los planes de financiación'
      )
    );
  } finally {
    dispatch(completeRequest('FinancialDetails'));
  }
};

export const updateFinancialPlanDetail = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('FinancialDetailsUpdate'));
  try {
    const payload = await nodeRequest.put(
      `financing-plan-to-categories/${params.id}`,
      params
    );
    dispatch(savedDetailedPlan());
    payload.status &&
      dispatch(
        newAlert(
          'success',
          'Atualización Exitosa',
          'Detalle del plan de financiación actualizado exitosamente'
        )
      );
    dispatch(fetchDetailedPlans(fetchParams));
  } catch (errors) {
    const body = await errors.json();

    dispatch(errorOnRequest('FinancialDetailsUpdate', body.errors));
    _get(body, 'errors.base') &&
      dispatch(newAlert('error', 'ERROR:', body.errors.base));
  } finally {
    dispatch(completeRequest('FinancialDetailsUpdate'));
  }
};

export const createFinancialPlanDetail = (
  params = {},
  fetchParams = {}
) => async dispatch => {
  dispatch(sendRequest('FinancialDetailsUpdate'));
  try {
    const payload = await nodeRequest.post(
      'financing-plan-to-categories',
      params
    );
    dispatch(savedDetailedPlan());
    payload.status &&
      dispatch(
        newAlert(
          'success',
          'Creación Exitosa',
          'Detalle del plan de financiación creado exitosamente'
        )
      );
    dispatch(fetchDetailedPlans(fetchParams));
  } catch (errors) {
    const body = await errors.json();

    dispatch(errorOnRequest('FinancialDetailsUpdate', body.errors));
    _get(body, 'errors.base') &&
      dispatch(newAlert('error', 'ERROR:', body.errors.base));
  } finally {
    dispatch(completeRequest('FinancialDetailsUpdate'));
  }
};
