import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Formik, Form, Field } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import SegmentHeader from '../../shared/SegmentHeader';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import StepperButtons from '../../shared/StepperButtons';
import { validationSchema } from './PortalSupplierDataValidation';
import mapListToOptions from '../../../helpers/mapListToOptions';
import OurDatePicker from '../../shared/CustomDatePicker';
import moment from 'moment-timezone';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import ActivityIndicator from '../../shared/ActivityIndicator';

import _get from 'lodash/get';
import ConfirmDialog from '../../shared/Modals/ConfirmDialog';
import { ValidationMethodOptions, ValidationMethodTypes } from '../../../constants/enums';

class PortalSupplierDataPage extends Component {
  state = {
    clerk: '',
    sellerDataAuthorization: true,
    disabledNext: false,
    blockedModalNotification: true,
    isSellerBlocked: false,
    isBiometricNullInPosModalNotification: false,
    isUserNotAllowedInBiometric: false,
    filteredValidationOptions: [],
    posId: null
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
    this.props.fetchPOS();
    this.props.fetchSecurityQuestionsCausals();
    if (this.props.userConfig.pos == '') {
      this.props.stepSuccess({ sellers: [] });
    } else {
      this.filterPointOfSales(this.props.pointsOfSale.find(pos => pos.id === this.props.userConfig.pos));
    }
  }

  onSubmit = values => {
    const {
      gnpGasApplianceSaleEvaluation,
      gnpCategories,
      gasApplianceCategories,
      useScoring,
      pointsOfSale,
      isSecureSale,
      isBiometricValidationInSecureSaleActive,
      promissory,
      isCosigner,
      debtorCache
    } = this.props;

    const params = {
      pointOfSale: values.pointOfSale,
      saleChannel: Number(values.sellingChannel),
      soldAt: moment.utc(values.saleDate).format('YYYY-MM-DD'),
      useScoring: useScoring,
      validationMethod: values.validationMethod || '0',
      promissoryCode: promissory && promissory.code ? promissory.code : '',
      isCosigner,
      debtorCache
    };
    if (gnpGasApplianceSaleEvaluation) {
      if (
        gnpGasApplianceSaleEvaluation['gnp_sale']['make_gnp_sale'] &&
        gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_is_gnp'] &&
        gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_has_gnp_articles']
      ) {
        params['categoryIdIn'] = gnpCategories.map(c => c.id);
        params['get_article_extra_quota'] = false;
      } else {
        if (
          gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'supplier_has_gas_appliance_articles'
          ] &&
          gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'any_breached_policy'
          ] &&
          !gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'any_no_gas_appliance_policy'
          ] &&
          gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'any_gas_appliance_policy'
          ]
        ) {
          params['categoryIdIn'] = gasApplianceCategories.map(c => c.id);
          params['get_article_extra_quota'] = false;
        }
      }
    }

    const posSelected = pointsOfSale.find(pos => pos.id === params.pointOfSale).attributes;
    const method = ValidationMethodOptions.find(option => option.id === values.validationMethod);

    if (isSecureSale && isBiometricValidationInSecureSaleActive) {
      if (
        posSelected.biometricApiUrl &&
        posSelected.methodValidation !== 'datacredito' &&
        method && [ValidationMethodTypes.FingerprintBiometric, ValidationMethodTypes.FacialBiometric].includes(method.name)
      ) {
        this.props.checkSixthStep(
          params,
          {
            clerk: values.clerkId,
            clerkName: values.clerkName,
            saleDate: moment(values.saleDate).format('YYYY-MM-DD'),
            deliverToPointOfSale: values.deliverToPointOfSale,
            validationMethod: values.validationMethod,
            posCanUseCrosscore: posSelected.canUseCrosscore
          },
          this.props.routes[this.nextStep],
          this.props.process
        );
      } else if (posSelected.hasSecurityQuestions && posSelected.methodValidation === 'datacredito') {
        this.setState({ isUserNotAllowedInBiometric: true });
      } else {
        this.setState({ isBiometricNullInPosModalNotification: true });
      }
    } else {
      this.props.checkSixthStep(
        params,
        {
          clerk: values.clerkId,
          clerkName: values.clerkName,
          saleDate: moment(values.saleDate).format('YYYY-MM-DD'),
          deliverToPointOfSale: values.deliverToPointOfSale,
          validationMethod: values.validationMethod,
          posCanUseCrosscore: posSelected.canUseCrosscore
        },
        this.props.routes[this.nextStep],
        this.props.process
      );
    }
  };

  _getSuggestions = async (array, value) => {
    const items = array.map(item => ({
      label:
        _get(item, 'attributes.first_name', '') +
        ' ' +
        _get(item, 'attributes.last_name', ''),
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  _fetchSellers = async id => {
    await this.props.fetchSellers({
      id: id
    });
  };

  _fetchSaleChannels = async id => {
    await this.props.fetchPointOfSaleSaleChannels({
      id
    });
  };

  _isRemoteSale = () => {
    return (
      this.props.process === 'remoteDigitalSaleWithUUP' ||
      this.props.process === 'remoteDigitalSaleWithoutUUP'
    );
  };

  _isDigitalSale = () => {
    return (
      this.props.process === 'digitalSaleWithUUP' ||
      this.props.process === 'digitalSaleWithouFaceId' ||
      this.props.process === 'digitalSaleWithoutUUPonContract'
    );
  };

  _toLocalDate = date => {
    const dateArray = date.split('/');
    return dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
  };

  _handleCloseDataAuthorizationModalNotification = () =>
    this.setState({ sellerDataAuthorization: true });

  _handleCloseModalModalNotification = () =>
    this.setState({ blockedModalNotification: false });

  _handleCloseIsBiometricNullInPosModalNotification = () =>
    this.setState({ isBiometricNullInPosModalNotification: false });

  _handleCloseIsUserNotAllowedInBiometric = () =>
    this.setState({ isUserNotAllowedInBiometric: false });

  filterPointOfSales(selectedPOS) {
    if (!selectedPOS) return;

    this.setState({ pos: selectedPOS });

    const filteredOptions = ValidationMethodOptions.filter(method => {
      return (
        (method.name === ValidationMethodTypes.FacialBiometric && selectedPOS.attributes.hasFacialBiometric) ||
        (method.name === ValidationMethodTypes.FingerprintBiometric && selectedPOS.attributes.hasFingerprintBiometric && selectedPOS.attributes.biometricApiUrl !== null) ||
        (method.name === ValidationMethodTypes.SecurityQuestions && selectedPOS.attributes.hasSecurityQuestions)
      );
    });

    this.setState({ filteredValidationOptions: filteredOptions });
  }

  ignoreBiometricValidation = (saleChannel) => {
    const {
      channelIgnoreBiometricsValidation,
      debtorIdentificationType,
      cosignerIdentificationType
    } = this.props;

    return (
      (saleChannel &&
        channelIgnoreBiometricsValidation &&
        channelIgnoreBiometricsValidation.some(item => item == saleChannel)) ||
      (debtorIdentificationType == 'Cédula de Extranjería' || cosignerIdentificationType == 'Cédula de Extranjería')
    );
  };

  render() {
    const {
      classes,
      process,
      pointsOfSale = [],
      sellers = [],
      saleChannels = [],
      pointOfSaleSaleChannels = [],
      providerConfig,
      userConfig,
      serverErrors,
      orgSellerDataAuthorization,
      isBlackListActive
    } = this.props;

    const { filteredValidationOptions } = this.state;

    const isBiometricReaderAble = Boolean(filteredValidationOptions.find(option => {
      return [ValidationMethodTypes.FingerprintBiometric, ValidationMethodTypes.FacialBiometric].includes(option.name);
    }));

    let currentSaleChannels = pointOfSaleSaleChannels;
    if (!currentSaleChannels.length) {
      currentSaleChannels = saleChannels;
    }

    const sellingChannel = providerConfig.default_sale_channel ? providerConfig.default_sale_channel.id : userConfig.saleChannel || '';

    const initialValues = {
      pointOfSale: providerConfig.default_point_of_sale
        ? providerConfig.default_point_of_sale.id
        : userConfig.pos || '',
      clerkId: userConfig.clerk || '',
      clerkName: userConfig.clerkName || '',
      sellingChannel,
      saleDate: this._toLocalDate(
        new Date().toLocaleDateString('es-CO', {
          timeZone: 'America/Bogota'
        })
      ),
      hasBiometricValidation: filteredValidationOptions.length !== 0 && (this._isRemoteSale() || (!this._isRemoteSale() && !this.ignoreBiometricValidation(sellingChannel))),
      validationMethod: userConfig.validationMethod || '',
      process,
      deliverToPointOfSale:
        providerConfig.site_delivery !== null
          ? providerConfig.site_delivery
          : false,
      minDate: new Date().getTime() - 15552000000
    };
    if (saleChannels.length === 0) {
      return <ActivityIndicator />;
    }
    return (
      <div>
        <Paper className={classes.container}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue
            }) => (
              <Form className={classes.form}>
                <SegmentHeader className={classes.segmentHeader}>
                  INFORMACIÓN PROVEEDOR / CONTRATISTA
                </SegmentHeader>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
                    <Field
                      render={({ field, form }) => {
                        return (
                          <SimpleSelect
                            name={'pointOfSale'}
                            label={'Punto de venta:'}
                            options={mapListToOptions(pointsOfSale, {
                              raw: false,
                              property: 'name',
                              byAttribute: true
                            })}
                            onChange={e => {
                              setFieldValue('pointOfSale', e.target.value);
                              this._fetchSellers(parseInt(e.target.value));
                              setFieldValue('sellingChannel', '');
                              setFieldValue('validationMethod', '');
                              this._fetchSaleChannels(parseInt(e.target.value));

                              const selectedPOS = pointsOfSale.find(pos => pos.id === e.target.value);

                              this.filterPointOfSales(selectedPOS);
                            }}
                            error={
                              (touched.pointOfSale && !!errors.pointOfSale) ||
                              !!serverErrors.point_of_sale
                            }
                            helperText={
                              (touched.pointOfSale && errors.pointOfSale) ||
                              serverErrors.point_of_sale
                            }
                            value={values.pointOfSale}
                            field={field}
                            form={form}
                            disabled={!providerConfig.can_select_point_of_sale}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
                    <Field
                      name="clerkId"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <AutocompleteInput
                            id="clerkId"
                            name="clerkId"
                            label="Vendedor:"
                            margin="normal"
                            variant="outlined"
                            error={
                              (touched.clerkId && !!errors.clerkId) ||
                              !!serverErrors.salesman
                            }
                            helperText={
                              (touched.clerkId && errors.clerkId) ||
                              serverErrors.salesman
                            }
                            value={this.state.clerk || values.clerkName || ''}
                            onChange={change => {
                              setFieldValue('clerkId', '');
                              setFieldValue('clerkName', '');
                              this.setState({ clerk: change });
                            }}
                            suggestions={sellers}
                            getSuggestions={value => this._getSuggestions(sellers, value)}
                            onSuggestionSelected={item => {
                              setFieldValue('clerkName', item.label);
                              setFieldValue('clerkId', item.value);

                              const sellerSelected = sellers.filter(seller => seller.id == item.value);
                              const sellerPersonalDataHandling = sellerSelected[0].attributes.personal_data_handling;

                              this.setState({ disabledNext: sellerPersonalDataHandling === null });
                              this.setState({
                                sellerDataAuthorization: sellerPersonalDataHandling,
                                isSellerBlocked: sellerSelected[0].attributes.blocked,
                                blockedModalNotification: sellerSelected[0].attributes.blocked
                              });
                            }}
                            field={field}
                            form={form}
                            classes={{ root: classes.autocompleteRoot }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
                    <SimpleSelect
                      name={'sellingChannel'}
                      label={'Canal de venta:'}
                      options={mapListToOptions(currentSaleChannels)}
                      onChange={e => {
                        handleChange(e);

                        setFieldValue('hasBiometricValidation', filteredValidationOptions.length !== 0 && (this._isRemoteSale() || (!this._isRemoteSale() && !this.ignoreBiometricValidation(e.target.value))));
                        setFieldValue('validationMethod', '');
                      }}
                      error={
                        (touched.sellingChannel && !!errors.sellingChannel) ||
                        !!serverErrors.sale_channel
                      }
                      helperText={
                        (touched.sellingChannel && errors.sellingChannel) ||
                        serverErrors.sale_channel
                      }
                      value={values.sellingChannel}
                      disabled={
                        !providerConfig.can_select_sale_channel
                      }
                    />
                  </Grid>
                  {Boolean(values.pointOfSale) && filteredValidationOptions.length !== 0 &&
                    ((process === 'portal' && !this.ignoreBiometricValidation(values.sellingChannel)) || this._isRemoteSale()) && (
                      <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
                        <SimpleSelect
                          name={'validationMethod'}
                          label={'Método de validación:'}
                          options={mapListToOptions(filteredValidationOptions)}
                          onChange={handleChange}
                          error={touched.validationMethod && !!errors.validationMethod}
                          helperText={touched.validationMethod && errors.validationMethod}
                          value={values.validationMethod}
                        />
                      </Grid>
                    )}
                </Grid>
                <SegmentHeader className={classes.segmentHeader}>
                  INFORMACIÓN DE SOLICITUD DE VENTA
                </SegmentHeader>
                <Grid
                  container
                  spacing={24}
                  className={classes.registerDateContainer}
                  alignItems="flex-start"
                >
                  <Grid item sm={6} xs={12}>
                    <OurDatePicker
                      name={'saleDate'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="No debe superar los 6 meses"
                      minDate={moment(values.minDate).format('YYYY/MM/DD')}
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha de venta:"
                      value={moment(values.saleDate).format('YYYY/MM/DD')}
                      onChange={e => setFieldValue('saleDate', e)}
                      onError={() => setFieldValue('saleDate', null)}
                      error={
                        (touched.saleDate && !!errors.saleDate) ||
                        !!serverErrors.sold_at
                      }
                      helperText={
                        (touched.saleDate && errors.saleDate) ||
                        serverErrors.sold_at
                      }
                      disabled={this._isRemoteSale() || this._isDigitalSale()}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <StepperButtons
                  onSubmit={handleSubmit}
                  goBack={() => {
                    this.props.goBack();
                    this.props.resetBaseErrorModal();
                  }}
                  disabledNext={
                    (this.state.disabledNext && orgSellerDataAuthorization) ||
                    (isBlackListActive && this.state.isSellerBlocked)
                  }
                />
              </Form>
            )}
          />
          {this.state.sellerDataAuthorization === null &&
            orgSellerDataAuthorization && (
              <ConfirmDialog
                title={
                  'Notificación del uso de tratamiento de datos personales'
                }
                text={'El vendedor seleccionado no ha dado respuesta a la autorización de tratamiento de datos personales'}
                buttonTextConfirm={'Aceptar'}
                disableBackdropClick={true}
                onClose={this._handleCloseDataAuthorizationModalNotification}
                onConfirm={this._handleCloseDataAuthorizationModalNotification}
              />
            )}
          {this.state.blockedModalNotification &&
            isBlackListActive && this.state.isSellerBlocked && (
              <ConfirmDialog
                title={
                  'Notificación de bloqueo'
                }
                text={`Lo sentimos, tiene deshabilitada esta función. Por favor, comuniquese con el area comercial.`}
                buttonTextConfirm={'Aceptar'}
                disableBackdropClick={true}
                onClose={this._handleCloseModalModalNotification}
                onConfirm={this._handleCloseModalModalNotification}
              />
            )}

          {this.state.isBiometricNullInPosModalNotification && (
            <ConfirmDialog
              title={
                'Notificación de error'
              }
              text={isBiometricReaderAble ? 'Este tipo de venta solo se puede realizar por validación biométrica.' : `Lo sentimos, este punto de venta tiene desactivada la validación por biometría y no se puede avanzar con la venta.`}
              buttonTextConfirm={'Aceptar'}
              disableBackdropClick={true}
              onClose={this._handleCloseIsBiometricNullInPosModalNotification}
              onConfirm={this._handleCloseIsBiometricNullInPosModalNotification}
            />
          )}

          {this.state.isUserNotAllowedInBiometric && (
            <ConfirmDialog
              title={
                'Notificación de error'
              }
              text={`Lo sentimos, el usuario que registra la venta no está configurado para biometría en el punto seleccionado`}
              buttonTextConfirm={'Aceptar'}
              disableBackdropClick={true}
              onClose={this._handleCloseIsUserNotAllowedInBiometric}
              onConfirm={this._handleCloseIsUserNotAllowedInBiometric}
            />
          )}
        </Paper>
      </div>
    );
  }
}
const styles = theme => ({
  container: {},
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  gridItem: {
    paddingTop: '12 !important',
    paddingBottom: '12 !important'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  autocompleteRoot: {
    marginTop: 16,
    marginBottom: 8
  }
});

PortalSupplierDataPage.propTypes = {
  classes: PropTypes.object.isRequired,
  details: PropTypes.object,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(PortalSupplierDataPage);
